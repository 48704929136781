import { Notice, QueuePosition } from "bouncer-waitingroom";

export class WaitingRoomHandler {
    // progress bar
    private progressBarElement: HTMLDivElement;
    private progressStripeElement: HTMLDivElement;
    private progressStripe2Element: HTMLDivElement;
    private queueProgressBarValueElement: HTMLSpanElement;

    // postion info
    private positionInfoElement: HTMLParagraphElement;

    // notices
    private noticeListElement: HTMLUListElement;

    // views
    private waitingContent: HTMLDivElement;
    private enterContent: HTMLDivElement;


    constructor() {
        this.progressBarElement = document.querySelector("#progress-bar") as HTMLDivElement;
        this.progressStripeElement = document.querySelector("#progress-stripe") as HTMLDivElement;
        this.progressStripe2Element = document.querySelector("#progress-stripe-2") as HTMLDivElement;
        this.queueProgressBarValueElement = document.querySelector("#queue-progress-bar-value") as HTMLSpanElement;

        this.positionInfoElement = document.querySelector("#position-info") as HTMLParagraphElement;

        this.noticeListElement = document.querySelector("#notice-list") as HTMLUListElement;

        this.waitingContent = document.querySelector("#waiting") as HTMLDivElement;
        this.enterContent = document.querySelector("#enter") as HTMLDivElement;

        this.registerHandlers();
    }


    private registerHandlers(): void {
        document.addEventListener("bouncer:queuePositionUpdated", (e) => this.updateQueuePosition(e.detail));
        document.addEventListener("bouncer:noticesUpdated", (e) => this.updateNotices(e.detail));

        //document.addEventListener("bouncer:stateUpdated", (e) => console.log("handling event stateUpdated", e));
        //document.addEventListener("bouncer:connectionClosed", (e) => console.log("handling event connectionClosed", e));
    }

    private updateQueuePosition(queuePosition: QueuePosition | null): void {
        this.updateProgressText(queuePosition);
        this.updateProgressBar(queuePosition);
    }

    private updateProgressBar(queuePosition: QueuePosition | null): void {
        let progress = 0;

        if (queuePosition !== null) {
            progress = queuePosition.progress;
        }

        const queueProgressRoundedPercentage = `${Math.round(progress)}%`;
        const queueProgressPercentage = `${progress}%`;

        this.progressBarElement.style.width = queueProgressPercentage;
        this.progressStripeElement.style.left = queueProgressPercentage;
        this.progressStripe2Element.style.left = queueProgressPercentage;
        this.queueProgressBarValueElement.innerText = queueProgressRoundedPercentage;
    }

    private updateProgressText(queuePosition: QueuePosition | null): void {
        if (queuePosition === null) {
            console.info("prequeue positionInfoElement text applied")

            this.positionInfoElement.innerText = "Du bist im Vorwarteraum. Deine Position wird dir gleich angezeigt!"

            return;
        }

        if (queuePosition.position === 1) {
            console.info("one ticket ahead positionInfoElement text applied")

            this.positionInfoElement.innerText = "Ein Fan ist vor Dir!"

            return;
        }

        if (queuePosition.position <= 0) {
            console.info("its this tickets turn positionInfoElement text applied")

            this.positionInfoElement.innerText = "Du bist der N�chste!"

            this.waitingContent.style.display = "none";
            this.enterContent.style.display = "block";

            return;
        }

        this.positionInfoElement.innerText = `${queuePosition.position} Fans sind vor Dir!`;
    }

    private updateNotices(notices: Array<Notice>): void {
        let updatedNoticeList = "";
        notices.forEach(n => updatedNoticeList += `<li>${n.message}</li>`);

        this.noticeListElement.innerHTML = updatedNoticeList;
    }
}
